<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row
          align-v="center"
          align-h="between"
        >
          <b-col cols="auto">
            <h4>
              {{ $t('members') }}
            </h4>
          </b-col>
          <b-col cols="auto">
            <b-form>
              <b-form-group
                id="search"
                label-for="search"
                style="width:300px;"
              >
                <b-form-input
                  id="search"
                  v-model="membersSearch"
                  :placeholder="$t('search')"
                />
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>

        <!--  -->
        <b-row
          align-h="end"
          class="my-1"
        >
          <!-- <b-col>
            <span>{{ $t('members') }}</span>

          </b-col> -->
          <b-col cols="auto">
            <b-form-checkbox
              id="checkbox1"
              v-model="referralLinkCheck"
              name="checkbox1"
            >
              {{ $t('registered_with_referral_link') }}
            </b-form-checkbox>
          </b-col>
        </b-row>

        <!--  -->
        <b-row>
          <b-col cols="12">
            <b-table
              sticky-header="1000px"
              no-border-collapse
              responsive
              striped
              hover
              :current-page="currentPage"
              :per-page="perPage"
              :fields="membersfields"
              :items="filteredMembers"
            >
              <template #cell(reffer_link)="data">
                <feather-icon
                  v-if="data.item.reffer_link"
                  icon="CheckIcon"
                />
                <feather-icon
                  v-else
                  icon="XIcon"
                />
              </template>
              <template #cell(actions)="data">
                <b-button
                  :id="`popover-button-variant-${data.item.id}`"
                  style="border:none; background-color:transparent !important"
                  size="sm"
                  href="#"
                  pill
                  tabindex="0"
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    color="black"
                    style="width:16px"
                  />
                </b-button>
                <b-popover
                  :target="`popover-button-variant-${data.item.id}`"
                  variant="danger"
                  triggers="focus"
                >
                  <b-button
                    variant="success"
                    size="sm"
                    style="margin: 8px auto; display: block; width: 140px"
                    @click="()=>moneyTransferHandle(data.item)"
                  >
                    {{ $t('money_transfer') }}
                  </b-button>
                  <b-button
                    size="sm"
                    variant="primary"
                    fluid
                    style="margin: 8px auto; display: block; width: 140px"
                    @click="()=>detailsPage(data.item)"
                  >
                    {{ $t('details') }}
                  </b-button>
                </b-popover>
              </template>
            </b-table>
            <b-col
              v-if="filteredMembers.length === 0"
              class="text-center"
            >
              {{ $t('no_item_exsist') }}
            </b-col>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-pagination
              v-if="filteredMembers.length > perPage"
              v-model="currentPage"
              :total-rows="filteredMembers.length"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="mt-2 mb-1"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <!-- modal -->
    <b-modal
      v-model="transferModal"
      :title="$t('money_transfer')"
      size="md"
      ok-only
    >
      <b-row
        class="m-2"
        align-h="center"
        align-v="center"
      >
        <b-col cols="auto">
          {{ $t('type') }}
        </b-col>
        <b-col cols="auto">
          <b-form-group class="mx-auto mb-0">
            <b-form-checkbox-group
              v-model="transferType"
              button-variant="primary"
              button-size="sm"
              buttons
              class="ibpannel-checkbox-group"
              name="butons1"
              :options="modalOptions"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        class="m-2"
        align-h="center"
        align-v="center"
      >
        <b-col cols="12">
          <ValidationObserver ref="transferForm">
            <b-form>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('amount')"
                rules="required"
              >
                <b-form-group
                  id="amount"
                  :label="$t('amount')"
                  label-for="amount"
                >
                  <b-form-input
                    id="amount"
                    v-model="transferForm.amount"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('description')"
                rules="required"
              >
                <b-form-group
                  id="description"
                  :label="$t('description')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="transferForm.description"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
              <!--  -->
              <validation-provider
                v-slot="{ errors }"
                :name="$t('code')"
                rules="required"
              >
                <b-form-group
                  id="code"
                  :label="$t('code')"
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    v-model="transferForm.code"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>

            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
      <template #modal-footer>
        <button
          type="submit"
          varient="danger"
          class="btn btn-primary btn-md mx-auto my-1"
          @click="transferMoneySubmit"
        >
          {{ $t('submit') }}
        </button>
      </template>
    </b-modal>

  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BTable,
  BButton,
  BPagination,
  BModal,
  BPopover,
  BFormCheckboxGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BTable,
    BButton,
    BPagination,
    BModal,
    BPopover,
    BFormCheckboxGroup,
  },

  data() {
    return {
      membersSearch: '',
      referralLinkCheck: false,
      subIdCheck: false,
      membersTable: [
        {
          id: 1,
          name: 'test1',
          equitd: 'test',
          balance: 100000,
          reffer_link: true,
          sub_id: true,
        },
        {
          id: 2,
          name: 'test2',
          equitd: 'test',
          balance: 200000,
          reffer_link: false,
          sub_id: true,
        },
        {
          id: 3,
          name: 'test3',
          equitd: 'test',
          balance: 300000,
          reffer_link: true,
          sub_id: false,
        },
        {
          id: 4,
          name: 'test1',
          equitd: 'test',
          balance: 100000,
          reffer_link: true,
          sub_id: true,
        },
        {
          id: 5,
          name: 'test2',
          equitd: 'test',
          balance: 200000,
          reffer_link: false,
          sub_id: true,
        },
        {
          id: 6,
          name: 'test3',
          equitd: 'test',
          balance: 300000,
          reffer_link: true,
          sub_id: false,
        },
        {
          id: 7,
          name: 'test1',
          equitd: 'test',
          balance: 100000,
          reffer_link: true,
          sub_id: true,
        },
        {
          id: 8,
          name: 'test2',
          equitd: 'test',
          balance: 200000,
          reffer_link: false,
          sub_id: true,
        },
        {
          id: 9,
          name: 'test3',
          equitd: 'test',
          balance: 300000,
          reffer_link: true,
          sub_id: false,
        },
        {
          id: 10,
          name: 'test1',
          equitd: 'test',
          balance: 100000,
          reffer_link: true,
          sub_id: true,
        },
        {
          id: 11,
          name: 'test2',
          equitd: 'test',
          balance: 200000,
          reffer_link: false,
          sub_id: true,
        },
        {
          id: 12,
          name: 'test3',
          equitd: 'test',
          balance: 300000,
          reffer_link: true,
          sub_id: false,
        },
      ],
      membersfields: [
        { key: 'name', label: this.$t('name') },
        { key: 'equitd', label: this.$t('equitd') },
        { key: 'balance', label: this.$t('balance') },
        { key: 'reffer_link', label: this.$t('reffer_link') },
        { key: 'actions', label: this.$t('actions'), thStyle: { width: '30px', textAlign: 'center' } },
      ],
      perPage: 10,
      currentPage: 1,
      transferModal: false,
      transferType: [],
      modalOptions: [
        { text: this.$t('Deposit'), value: 0 },
        { text: this.$t('rerbate'), value: 1 },
      ],
      transferForm: {
        amount: '',
        description: '',
        code: '',
        memberId: '',
      },
    }
  },

  computed: {
    filteredMembers() {
      const newMembers = this.membersTable
        .filter(item => JSON.stringify(Object.values(item)).toLowerCase().includes(this.membersSearch.toLowerCase()))

      if (this.referralLinkCheck) {
        return newMembers.filter(item => item.reffer_link)
      }
      return newMembers
    },
  },

  watch: {
    transferType(newVal, oldVal) {
      if (newVal.length >= 2) {
        return newVal.shift()
      }
      if (newVal.length === 0) {
        return oldVal[0] === 1 ? newVal.push(0) : newVal.push(1)
      }
      return newVal
    },

    referralLinkCheck() {
      this.currentPage = 1
    },
    subIdCheck() {
      this.currentPage = 1
    },
  },

  methods: {
    detailsPage(item) {
      this.$router.push(`/${this.$i18n.locale}/ib-pannel/${item.id}/details`)
    },
    moneyTransferHandle(item) {
      this.transferModal = true
      this.transferForm.memberId = item.id
    },
    async transferMoneySubmit() {
      const success = await this.$refs.transferForm.validate()
      if (success && this.transferType.length !== 0) {
        this.transferModal = false
        console.log(this.transferForm)

        setTimeout(() => {
          this.transferForm.amount = ''
          this.transferForm.description = ''
          this.transferForm.code = ''
        }, 1000)
      }
    },
  },
}
</script>
